<template>
  <div id="app">
    <div class="my-home" v-if="loadingIndex" style="z-index: 99">
      <div class="my-home__main">
        <div class="loader"></div>
      </div>
    </div>
    <Header v-if="!loadingIndex" :location="userLocation" />
    <router-view v-if="!loadingIndex" />
    <Footer v-if="!loadingIndex" />
    <sidebar v-if="!loadingIndex" />
  </div>
</template>

<script>
import { getUserInfoApi } from "@/api";
export default {
  name: "App",
  components: {
    // 异步加载组件
    Header: () => import("./components/HeaderView.vue"),
    Footer: () => import("./components/FooterView.vue"),
    sidebar: () => import("./components/sidebar.vue"),
  },
  data() {
    return {
      loadingIndex: true,
      userLocation: "苏州",
      authToken: this.$cookies.get("authToken"), // 获取token
      userInfo: {}, // 获取用户信息
    };
  },
  created() {
    const prevRoute = this.$previousRoute(); // 上一个路由的信息，如路径、名称、参数等
    if (prevRoute.path !== "/login" && this.authToken) {
      this.getUserInfo();
    }
    let times = 500; // 加载延时
    setTimeout(() => {
      this.loadingIndex = false;
    }, times);
  },
  mounted() {
    this.checkDeviceMode();
    window.addEventListener("resize", this.checkDeviceMode); // 窗口大小变化时重新检查设备模式
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDeviceMode); // 组件销毁时移除事件监听
  },
  methods: {
    async getUserInfo() {
      let res = await getUserInfoApi();
      if (res.code === 200) {
        this.userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(res.data));
      }
    },
    checkDeviceMode() {
      const deviceWidth = document.documentElement.clientWidth; // 获取当前窗口宽度
      if (deviceWidth < 768) {
        // 判断是否为移动设备模式
        this.bodyScale(); // 移动端，执行缩放
      } else {
        this.resetScale(); // PC端，重置为默认宽度
      }
    },
    bodyScale() {
      const deviceWidth = document.documentElement.clientWidth;
      const scale = deviceWidth / 1300; // 分母——设计稿的尺寸
      document.body.style.transform = `scale(${scale})`;
      document.body.style.transformOrigin = "top left"; // 设置缩放原点，保持左上角不变
    },

    resetScale() {
      document.body.style.transform = ""; // 恢复原始大小
      document.body.style.transformOrigin = ""; // 保持左上角不变
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1300px;
  width: 100%;
  height: 100%;
}

html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
}

.my-home {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  --_c: no-repeat radial-gradient(farthest-side, #0084ff 92%, #0000);
  background: var(--_c) top, var(--_c) left, var(--_c) right, var(--_c) bottom;
  background-size: 12px 12px;
  animation: l7 1s infinite;
}

@keyframes l7 {
  to {
    transform: rotate(0.5turn);
  }
}

.my-home__main {
  user-select: none;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(144, 147, 153, 0.3);
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

a {
  color: #ffffff;
  text-decoration: none;
}
</style>
